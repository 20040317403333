<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-5">Delivery Addresses</h4>
      <div class="row mb-md-2">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show
              <b-form-select
                v-model="perPage"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
              entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div
            id="tickets-table_filter"
            class="dataTables_filter text-md-right"
          >
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
      </div>

      <div class="table-responsive mb-0">
        <b-table
          :items="deliveryAddress"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          ref="selectableTable"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
          empty-text="No record found"
          empty-filtered-text="No search result found"
        >
          <template v-slot:cell(actions)="deliveryAddress">
            <i
              style="color:red;cursor: pointer;"
              class="fas fa-trash-alt"
              @click="deleteData(deliveryAddress)"
            ></i>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    deliveryAddress: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkedNames: [],
      isSelectAll: false,
      sortDesc: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      fields: [
        { key: "lat", label: "Lat", sortable: true },
        { key: "lng", label: "lng", sortable: true },
        { key: "postcode", label: "Postcode", sortable: true },
        { key: "state", label: "State", sortable: true },
        { key: "street", label: "Street", sortable: true },
        { key: "actions", sortable: true },
      ],
    };
  },

  mounted() {},

  computed: {
    rows() {
      return this.deliveryAddress.length;
    },
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    deleteData(object) {
      this.$emit(
        "showBulkPopupDeliveryAddress",
        object,
        "delete-delivery-address"
      );
    },
  },
};
</script>
