<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="col-12 mt-2">
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <router-link to="/customer">
              <button class="btn btn-info rounded-pill mb-2">
                Back
              </button>
            </router-link>
            <h4 class="card-title mb-0">{{ headerTitle }}</h4>
            <br />
            <div
              v-if="alert || apiResponceError"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              class="alert alert-danger"
            >
              {{ alertText }}
            </div>
          </div>
        </div>
        <div class="card-body">
          <div>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-2"
                label="Name *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="name"
                  type="text"
                  required
                  placeholder="Enter Name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Email *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="email"
                  @keyup="isEmailValid"
                  type="text"
                  required
                  placeholder="Enter Email"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Phone Number *"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  @keyup="enforcePhoneFormat"
                  v-model="phone_number"
                  required
                  placeholder="Enter Phone Number"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Password * "
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  placeholder="Enter Password"
                ></b-form-input>
              </b-form-group>

              <div
                style="display:flex;justify-content:space-between;align-items:center;"
              >
                <div style="width:33%">
                  <b-form-group label="Delivery  Address *">
                    <GmapAutocomplete
                      id="autocomplete"
                      ref="autocomplete"
                      class="form-control"
                      placeholder="Please type address"
                      @place_changed="deliveryAddresses"
                    >
                    </GmapAutocomplete>
                  </b-form-group>
                </div>
                <div style="width:20%;margin-left:10px">
                  <b-form-group label="Street No *">
                    <b-form-input
                      id="input-2"
                      v-model="street"
                      placeholder="Enter Street No"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div style="width:20%;margin-left:10px">
                  <b-form-group label="Floor">
                    <b-form-input
                      id="input-2"
                      v-model="floor"
                      placeholder="Enter Floor"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div style="width:20%;margin-left:10px">
                  <b-form-group label="House No *">
                    <b-form-input
                      id="input-2"
                      v-model="house_no"
                      placeholder="Enter House No"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div style="width:10%;padding-left:10px">
                  <a class="btn btn-info" @click="appendRow">Add</a>
                </div>
              </div>

              <b-form-group
                id="input-group-2"
                label="Profile Image:"
                label-for="input-2"
              >
                <input type="file" @change="profileImageShow" /><br /><br />
                <img
                  style="width: 140px;
                  height: 140px;
                  border-radius: 16px;"
                  v-if="profileimageUrl"
                  :src="profileimageUrl"
                />
              </b-form-group>

              <br /><br />

              <b-button ref="save" type="submit" class="btn-info"
                >Save</b-button
              >
            </b-form>
          </div>
        </div>
      </div>
      <br /><br />
      <template v-if="isShowModel">
        <VerficationModal
          :show="showModal"
          :userObj="userObj"
          :actionType="actionType"
          @deleteImageRecord="deleteImageRecord"
          @deleteDeliveryAddress="deleteDeliveryAddress"
          @closeModal="closeModal"
        />
      </template>
      <template>
        <DeliveryAddressView
          v-if="deliveryAddressView"
          :deliveryAddress="deliveryAddressArray"
          @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
        />
      </template>
      <br /><br />

      <br />
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";

import VerficationModal from "./VerficationModal";
import DeliveryAddressView from "@/view/pages/Customer/DeliveryAddressView";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
// import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  components: {
    // Multiselect,
    VerficationModal,
    // VueGoogleAutocomplete,
    DeliveryAddressView,
  },
  data() {
    return {
      current_address: [],
      deliveryAddressArray: [],
      tempDeletedDeliveryAddress: [],
      tempArrayDelte: [],
      deliveryAddresstempArray: "",
      headerTitle: "Add Customer",
      phone_number: "",
      password: "",
      currentAddressname: "",
      email: "",
      name: "",
      house_no: "",
      floor: "",
      street: "",
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      imagesShow: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      profileimageUrl: null,
      profileImage: null,
      isShowModel: false,
      alertText: "",
      alert: false,
      apiResponceError: false,
      showImagesViewTable: false,
      deliveryAddressView: false,
      price: "",
      show: true,
      apiUrl: "/customer/register",
    };
  },

  mounted() {
    if (this.$router.history.current.path == "/editcustomer") {
      this.apiUrl = "/customer/updateUser";
      this.showImagesViewTable = true;
      this.headerTitle = "Edit Customer";
      this.editDataApppend();
    }
    for (let ref in this.$refs) {
      this.$refs[ref].focus();
    }
  },
  methods: {
    isEmailValid: function isEmailValid() {
      this.apiResponceError = false;
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = "Please enter valid email address";
        this.changeClasss = false;
        this.alert = true;
      }
      return responce;
    },

    enforcePhoneFormat: function enforcePhoneFormat() {
      this.apiResponceError = false;
      var regex = /^(?:\+?\d{12})$/;
      var responce = regex.test(this.phone_number);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = "Please enter valid phone number";
        this.changeClasss = false;
        this.alert = true;
      }
      return responce;
    },
    editDataApppend() {
      this.editcustomer = JSON.parse(localStorage.getItem("editcustomer"));
      if (this.editcustomer.profile_image) {
        this.profileimageUrl = this.editcustomer.profile_image;
        this.profileImage = this.editcustomer.profile_image;
      }
      this.name = this.editcustomer.username;
      this.id = this.editcustomer.id;
      this.phone_number = this.editcustomer.phone_number;
      this.email = this.editcustomer.email;
      this.deliveryAddressArray = this.editcustomer.addresses;
      this.deliveryAddressView = true;
    },

    deliveryAddresses: function(addressData) {
      var postcode, city, state, street_number, route, street;
      var places = addressData;
      var lat = places.geometry.location.lat();
      var lng = places.geometry.location.lng();

      for (var i = 0; i < places.address_components.length; i++) {
        for (var j = 0; j < places.address_components[i].types.length; j++) {
          if (places.address_components[i].types[j] == "postal_code") {
            postcode = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "locality") {
            city = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "street_number") {
            street_number = places.address_components[i].long_name;
          }
          if (places.address_components[i].types[j] == "route") {
            route = places.address_components[i].long_name;
          }
          if (
            places.address_components[i].types[j] ==
            "administrative_area_level_1"
          ) {
            state = places.address_components[i].long_name;
          }
        }
      }
      street = route ? route : "" + " " + street_number ? street_number : "";

      // var parts = id.split("_");
      let data = {
        postcode: postcode ? postcode : "",
        street: street ? street : "",
        city: city ? city : "",
        state: state ? state : "",
        lat: lat ? lat : "",
        lng: lng ? lng : "",
        floor: "",
        house_no: "",
      };
      if (!postcode) {
        this.toasterMessageFalse(
          "This address does not have post code please try with different address"
        );
        return false;
      }
      // this.deliveryAddresstempArray.splice(parts[1], 1);
      this.deliveryAddresstempArray = data;
    },
    appendRow() {
      if (this.deliveryAddresstempArray == "") {
        this.toasterMessageError("Address Required");
        return false;
      }
      if (this.street == "") {
        this.toasterMessageError("Street Required");
        return false;
      }
      if (this.house_no == "") {
        this.toasterMessageError("House no Required");
        return false;
      }
      this.deliveryAddresstempArray.street = this.street;
      this.deliveryAddresstempArray.floor = this.floor;
      this.deliveryAddresstempArray.house_no = this.house_no;

      this.deliveryAddressArray.push(this.deliveryAddresstempArray);
      document.getElementById("autocomplete").value = "";
      this.house_no = "";
      this.floor = "";
      this.toasterMessageSuccess(
        "Address added successfully, please check below"
      );
      if (this.deliveryAddressArray.length > 0) {
        this.deliveryAddressView = true;
      }
      this.deliveryAddresstempArray = "";
    },

    toasterMessageSuccess(message) {
      Vue.$toast.success(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    toasterMessageError(message) {
      Vue.$toast.error(message, {
        position: "top-right",
        duration: 5000,
      });
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    closeModal(value) {
      this.isShowModel = value;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editRestaurant.images.splice(index, 1);
        this.loans = this.editRestaurant.images;
        localStorage.removeItem("editRestaurant");
        localStorage.setItem(
          "editRestaurant",
          JSON.stringify(this.editRestaurant)
        );
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteDeliveryAddress(object) {
      this.tempDeletedDeliveryAddress.push(object.item.id);
      let index = this.deliveryAddressArray.findIndex((x) => x === object.item);
      this.deliveryAddressArray.splice(index, 1);
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },
    CurrentAddress: function(addressData) {
      let data = {
        postcode: addressData.postal_code,
        street: addressData.route,
        city: addressData.locality,
        state: addressData.administrative_area_level_1,
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.current_address = data;
    },
    customLabel({ username, email }) {
      return `${username}—(${email})`;
    },
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    onSubmit(evt) {
      try {
        const formData = new FormData();
        evt.preventDefault();
        if (this.password.length > 0 && this.password.length < 6) {
          this.validationMessage("The Password must be at least 6 characters.");
          return false;
        } else {
          this.alert = false;
        }

        if (this.phone_number.length > 0 && this.phone_number.length < 12) {
          this.validationMessage(
            "The phone number must be at least 11 characters."
          );
          return false;
        } else {
          this.alert = false;
        }
        if (this.alert) {
          evt.preventDefault();
          return false;
        }

        formData.append("id", this.id);
        this.addLoaderClasses("save");
        formData.append("username", this.name);
        formData.append("email", this.email);
        formData.append("phone_number", this.phone_number);
        formData.append("password", this.password);
        formData.append("platform", "adminpanel");

        formData.append(
          "tempDeletedDeliveryAddress",
          this.tempDeletedDeliveryAddress
        );
        formData.append("addresses", JSON.stringify(this.deliveryAddressArray));
        if (this.profileImage) {
          formData.append("profile_image", this.profileImage);
        }

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              let message = "";
              if (this.id != "") {
                message = "Record has been updated successfully";
              } else {
                message = "Record has been added successfully";
              }
              localStorage.setItem("alertmessage", JSON.stringify(message));
              this.$router.push({ name: "customer" });
            }
            if (response.data.code == 422) {
              this.apiResponceError = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getAllCustomer() {
      try {
        axios.get(this.$path + "/dropdown-user-list").then((response) => {
          this.users = response.data.data;
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
